<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.localization.currency')}}</h4>
            <Currency @updateList="setCurrencyList"></Currency>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.localization.countries')}}</h4>
            <Country :currencyList="currencyList" @updateList="setCountryList"></Country>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.localization.city')}}</h4>
            <City :countryList="countryList"></City>
          </div>
        </div>
      </div>

    </div>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Country from "../../../components/management/localization/Country.vue";
import Currency from "../../../components/management/localization/Currency.vue";
import City from "../../../components/management/localization/City.vue";

//import axios from "axios";

/**
 * Rbac component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Country,
    Currency,
    City
},
  data() {
    return {
      currencyList: [],
      countryList: [],
      title: this.$t('menuitems.management.list.localization'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.localization'),
          active: true
        }
      ],
    };
  },
  validations: {

  },
  computed: {

  },
  methods: {
    setCurrencyList(list){
      this.currencyList = list;
    },
    setCountryList(list){
      this.countryList = list;
    }
  },
  created() {
    
  }
};
</script>
