<template>
  <div>
    <form class="needs-validation" @submit.prevent="submitNewCountry">
      <div class="form-group">
        <label for="newCountry_currency">{{$t('views.localization.currency')}}</label>
        <select v-model="newCountry.currencyId" class="form-control form-control-sm">
          <option v-for="item in currencyList" :key="item.id" :value="item.id">
            {{item.currency_name}}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="newCountry_name">{{$t('views.localization.country_name')}}</label>
        <input
            id="newCountry_name"
            v-model="newCountry.name"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newCountry.name.$error }"
        />
        <div v-if="submitted && $v.newCountry.name.$error" class="invalid-feedback">
          <span v-if="!$v.newCountry.name.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newCountry.name.minLength">
                        {{$t('validatePhrases.minLength')}}: 2
                      </span>
          <span v-if="!$v.newCountry.name.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 30
                      </span>
        </div>
      </div>
      <button class="btn btn-sm btn-success" type="submit">{{$t('popularWords.add')}}</button>
    </form>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{$t('views.localization.country_list')}}</h4>

        <div class="row mt-3">
          <div class="col-12">
            <table class="table table-hover mb-0">
              <tbody>
              <tr v-for="(item, index) in list" :key="index">
                <th>
                  <div>
                    {{item.country_name}} ({{item.currency.letter_code}})
                  </div>
                </th>
                <th>
                  <div class="button-items text-right">
                    <div @click="deleteCountry(item.id)" class="btn btn-sm btn-danger">{{$t('popularWords.delete')}}</div>
                  </div>
                </th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <popup
        :closeButton="deletePopup.closeButton"
        :actionButton="deletePopup.actionButton"
        :actionClass="deletePopup.actionClass"
        :show="deletePopup.show"
        @closePopup="closeDeletePopup"
        @actionPopup="deletePopupSubmit"
    >
      <div slot="header">{{$t('views.localization.delete_country')}}</div>
      <div slot="body">
        {{$t('views.localization.delete_country_text')}}
      </div>
    </popup>

  </div>
</template>

<script>

import {maxLength, minLength, minValue, required} from "vuelidate/lib/validators";
import axios from "axios";
import Popup from "@/components/Popup";

export default {
  name: "Country",
  components: {Popup},
  data(){
    return {
      list: [],
      newCountry: {
        name: '',
        currencyId: 0
      },
      submitted: false,
      deletedId: 0,
      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
    }
  },
  props: {
    currencyList: Array
  },
  watch: {
    list: function(val){
      this.$emit('updateList', val);
    }
  },
  computed: {

  },
  validations: {
    newCountry: {
      name: { required, minLength: minLength(2), maxLength: maxLength(250) },
      currencyId: { required, minValue: minValue(1) }
    }
  },
  methods: {
    submitNewCountry(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newCountry.$touch();
      if(!this.$v.newCountry.$invalid){
        let formData = new FormData();
        formData.append("name", this.newCountry.name);
        formData.append("currency_id", this.newCountry.currencyId);

        axios
            .post(`/v1/localization/add-country`, formData)
            .then(resp => {
              this.list = resp.data;
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 2,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    deleteCountry(id){
      this.deletedId = id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    deletePopupSubmit(){
      let formData = new FormData();
      formData.append("id", this.deletedId);

      axios
          .post(`/v1/localization/delete-country`, formData)
          .then(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.deleted'),
              time: 2,
              color: "success"
            });
            this.getAll();
            this.closeDeletePopup();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getAll(){
      axios
          .get(`/v1/localization/get-country`)
          .then(resp => {
            this.list = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getAll();
  }

};
</script>

<style scoped lang="css">

</style>
